import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
const ProfileModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <>
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-50"
        onClick={onClose}
      ></div>

      {/* Modal */}
      <div className="fixed inset-0 flex justify-center items-center z-50">
        <div className="bg-white rounded-lg shadow-lg w-96 p-6">
          <div className="flex justify-between items-center">
            <h2 className="text-[24px] font-bold">Update Profile</h2>
            <button
              className="px-2 py-1 bg-blue-500 text-white border-none rounded-lg hover:bg-blue-600"
              onClick={onClose}
            >
              <IoMdClose />
            </button>
          </div>
          <div className="mt-4">{children}</div>
        </div>
      </div>
    </>
  );
};

export default ProfileModal;
