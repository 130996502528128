import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
} from "@mui/material";

const filterUsers = (users, searchQuerry = null) => {
  // let filtered = users.filter((user) => !!user.label);
  let filtered = users.filter(
    (user) => !!user.label.toLowerCase().includes(searchQuerry.toLowerCase())
  );
  console.log("Filtered is :: ", filtered);
  return filtered;
};

const UsersMultiSelect = ({ users, handleSelectedUsers }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSelectAll, setIsSelectAll] = useState(false);
  useEffect(() => {
    if (users?.length > 0) {
      setFilteredUsers(users);
    }
  }, [users?.length]);
  console.log("users UsersMultiSelect", users, filteredUsers);
  // Handle changes in selected users
  const handleChange = (event, newValue, reason) => {
    if (searchTerm) {
      setSearchTerm("");
      setFilteredUsers(users);
    }
    setSelectedUsers(newValue);
    handleSelectedUsers(newValue);
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setFilteredUsers(filterUsers(users, value));
  };

  // Handle select all users
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSearchTerm("");
      setSelectedUsers(users);
      setFilteredUsers([]);
    } else {
      setSearchTerm("");
      setFilteredUsers(users);
      setSelectedUsers([]);
    }
    setIsSelectAll(event.target.checked);
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={filteredUsers}
        filterSelectedOptions
        value={selectedUsers}
        onChange={handleChange}
        disableCloseOnSelect
        limitTags={3}
        disabled={isSelectAll}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={selected ? <span /> : <span />}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search and select users..."
            onChange={handleSearchChange}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option.label} {...getTagProps({ index })} />
          ))
        }
        sx={{
          backgroundColor: "white",
          "&..MuiAutocomplete-listbox": { maxHeight: "100px" },
        }}
      />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSelectAll}
              onChange={handleSelectAll}
              sx={{
                color: "#fff",
                "&.Mui-checked": {
                  color: "#fff",
                },
              }}
            />
          }
          label="Select All"
        />
      </div>
    </div>
  );
};

export default UsersMultiSelect;
