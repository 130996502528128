import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { Toaster } from "react-hot-toast";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <Toaster
      position="top-left"
      toastOptions={{
        success: {
          duration: 4000,
          style: {
            background: "green",
            color: "white",
          },
        },
        error: {
          duration: 4000,
          style: {
            background: "red",
            color: "white",
          },
        },
      }}
    />
  </React.StrictMode>,
  document.getElementById("root")
);
