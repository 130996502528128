import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import image1 from "../../assets/image-4-2-1@2x.png";
import group from "../../assets/group.svg";
import ellipse from "../../assets/ellipse-1@2x.png";
import { useNavigate } from "react-router-dom";
import ProfileModal from "../Modals/ProfileModal";
import { IconButton, Menu, MenuItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { base_url } from "../../Global";

const Navbar = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();
    // Navigate to /login
    navigate("/login");
  };
  function onError() {
    console.log(errors);
  }
  const onSubmit = async (data) => {
    setLoading(true);
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      throw new Error("Access token not found in local storage");
    }
    try {
      const response = await fetch(base_url + "/auth/changeAdminCredentials", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: accessToken,
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      console.log(JSON.stringify(result, null, 2));
      if (!response.ok) {
        throw new Error(result.message);
      }

      toast.success("Credentials updated succesfuly");
      setModalOpen(false);
      reset();
    } catch (error) {
      console.log(error.message);

      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ProfileModal isOpen={isModalOpen} onClose={closeModal}>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className="bg-white p-6 rounded-lg shadow-md space-y-4"
        >
          {/* Password field */}
          <div className="space-y-2">
            <label htmlFor="password" className="block text-sm font-medium">
              Password
            </label>
            <input
              id="password"
              type="password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {errors.password && (
              <p className="text-red-500 text-xs">{errors.password.message}</p>
            )}
          </div>

          {/* New Password field */}
          <div className="space-y-2">
            <label htmlFor="newPassword" className="block text-sm font-medium ">
              New Password
            </label>
            <input
              id="newPassword"
              type="password"
              {...register("newPassword", {
                required: "New password is required",
                minLength: {
                  value: 6,
                  message: "New password must be at least 6 characters",
                },
                validate: (value) => {
                  if (value === document.getElementById("password").value) {
                    return "New password cannot be the same as the old password";
                  }
                  return true;
                },
              })}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {errors.newPassword && (
              <p className="text-red-500 text-xs">
                {errors.newPassword.message}
              </p>
            )}
          </div>
          <div className="mt-4">
            <button
              type="submit"
              disabled={loading}
              className={`w-full px-4 py-2 rounded-md border-none ${
                loading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
              } text-white focus:outline-none focus:ring-2 focus:ring-blue-500`}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </ProfileModal>
      <header class="self-stretch bg-black  w-screen mr-3 pr-10 pl-6 fixed z-[10] left-3  py-3 flex flex-row items-start justify-start  box-border  shrink-0">
        <div class="flex-1 rounded-xl bg-gray-200 flex flex-row items-center justify-between py-[0.688rem] pr-[0.688rem] pl-[1.375rem] box-border max-w-full gap-[1.25rem]">
          <div class="h-[4.625rem] w-[85.625rem] relative rounded-xl bg-gray-200 hidden max-w-full"></div>
          <img
            class="h-[2.75rem] w-[7.063rem] relative object-cover z-[1]"
            loading="lazy"
            alt=""
            src={image1}
          />

          <div class="flex flex-row items-center justify-start gap-[0rem_1.875rem]">
            <div>
              <IconButton
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={handleOpenMenu}
              >
                <img
                  class="h-[3.25rem] w-[3.25rem] relative rounded-[50%] object-cover z-[1]"
                  loading="lazy"
                  alt=""
                  src={ellipse}
                />
                {/* <AccountCircleIcon /> */}
              </IconButton>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={openModal}>Profile</MenuItem>
                <MenuItem onClick={handleCloseMenu}>My account</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
