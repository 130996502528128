import React, { useState, useEffect, useRef } from "react";
import vector12 from "../../assets/vector-12.svg";
import group51 from "../../assets/group-5-1.svg";
import Select from "react-select";
import MultipleEmailForm from "./MultipleEmailForm";
import { IoMdClose } from "react-icons/io";
// import MultiSelect from "./MultiSelect";
import { MultiSelect } from "react-multi-select-component";
import makeAnimated from "react-select/animated";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsersMultiSelect from "./UsersMultiSelect";
import { base_url } from "../../Global";
const animatedComponents = makeAnimated();

const Email = () => {
  const [users, setUsers] = useState([]);

  // const newAllUsers = allUsers
  const [notificationText, setNotificationText] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationsList, setNotificationsList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectFromMS, setselectFromMS] = useState([]);
  const [extraEmail, setExtraEmail] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(base_url + "/auth/allProfile");

        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }

        const data = await response.json(); // Parse JSON response
        // Extracting user name and user id
        console.log(data);
        let usersWithRequiredFields = data?.users
          ?.filter((user) => !!user.username?.trim())
          ?.map((user) => ({
            value: user._id,
            label: user.username,
            email: user.email,
          }));

        setUsers(usersWithRequiredFields); // Set users state with the extracted fields
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  // calll notification api

  const handleNotificationTitle = (event) => {
    const capitalizedValue =
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
    setNotificationTitle(capitalizedValue);
  };

  const handleNotificationTextChange = (event) => {
    const capitalizedValue =
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
    setNotificationText(capitalizedValue);
  };

  //   event.preventDefault();
  //   const userIdsTemp = selectFromMS.map((item) => item.value);
  //   const accessToken = localStorage.getItem("accessToken");
  //   if (!accessToken) {
  //     throw new Error("Access token not found in local storage");
  //   }
  //   // Perform API call here
  //   fetch(base_url + "/auth/send-notification", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       authorization: accessToken,

  //       // Add any other headers required by your API
  //     },
  //     body: JSON.stringify({
  //       notificationType: notificationTitle,
  //       userIds: userIdsTemp,
  //       notificationText: notificationText,
  //     }),
  //   })
  //     .then((response) => {
  //       // Handle response from API
  //       if (response.ok) {
  //         // Notification successfully sent
  //         console.log("Notification sent successfully");
  //         notify();
  //         // Clear input fields
  //         setNotificationTitle(""); // Assuming setSelectedOption is a state setter
  //         setNotificationText("");
  //         setselectFromMS([]); // Assuming setNotificationText is a state setter
  //         fetchUserNotification();
  //       } else {
  //         notif();
  //         // Notification failed
  //         console.error("Failed to send notification");
  //       }
  //       return response.json(); // Parse response body as JSON
  //     })
  //     .then((data) => {
  //       console.log("API Response:", data); // Log the parsed JSON response
  //       // window.location.reload(false);
  //     })
  //     .catch((error) => {
  //       // Handle error
  //       notif();
  //       console.error("Error:", error);
  //     });
  // };
  const removeEmail = (i) => {
    setExtraEmail(
      extraEmail.filter((data, index) => {
        return index !== i;
      })
    );
  };
  const AddEmail = (data) => {
    setExtraEmail([...extraEmail, { email: data?.Enteremail }]);
  };
  const sendEmail = (event) => {
    if (notificationText.length === 0 || notificationTitle.length === 0) {
      toast.error("title or body cannot be empty");
      return;
    }
    console.log(selectFromMS[0]);
    event.preventDefault();
    console.log(users[0]);
    let selectedUsers = users
      .filter((user) => selectFromMS.some((item) => item.value === user.value)) // Check each object inside selectFromMS
      .map((user) => ({
        id: user.value,
        email: user.email,
      }));
    selectedUsers = [...selectedUsers, ...extraEmail];
    console.log(selectedUsers);
    // if (!accessToken) {
    //   throw new Error("Access token not found in local storage");
    // }
    setLoading(true);
    fetch(base_url + "/auth/sendDirectEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subject: notificationTitle,
        to: selectedUsers,
        body: notificationText,
      }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Email sent successfully");
          setNotificationTitle("");
          setNotificationText("");
        } else {
          toast.error("Failed to send Email");
          throw new Error("failed to send email");
        }
        return response.json();
      })
      .then((data) => {
        console.log("API Response:", data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:21", error.message);

        setLoading(false);
      });
  };
  return (
    <section class="flex-1 rounded-xl w-full ml-[19rem] mt-[8rem] bg-gray-200 flex flex-col items-start justify-start pt-[2.125rem] px-[2.125rem] pb-[24.625rem] box-border gap-[0.875rem_0rem] max-w-full text-left  text-white font-gilroy mq925:pt-[1.375rem] mq925:pb-[16rem] mq925:box-border mq925:max-w-full mq450:pt-[1.25rem] mq450:pb-[10.375rem] mq450:box-border">
      <ToastContainer className="text-[1rem]" />
      <div class="flex-1 flex flex-col w-full items-end justify-start  min-w-[27.125rem] max-w-full  mq700:min-w-full">
        <div class="flex  flex-col items-start   w-full justify-between  max-w-full mq700:flex-wrap">
          {/* <h1 class="m-0  relative text-inherit leading-[2.375rem] text-[20px] capitalize font-inherit inline-block shrink-0 z-[1]  ">
            Email
          </h1>{" "} */}
          <MultipleEmailForm
            onDelete={removeEmail}
            Emails={extraEmail}
            onAdd={AddEmail}
          />
        </div>
      </div>

      <div class="self-stretch flex flex-row mt-7 flex-wrap items-start justify-start gap-[0rem_1.5rem] max-w-full">
        <div class="flex-1 flex flex-col items-start justify-start gap-[1.5rem_0rem] min-w-[20.063rem] max-w-full">
          <div class="self-stretch flex flex-col items-start justify-start gap-[0.875rem_0rem]">
            <div class="h-[0.75rem] relative leading-[1.125rem] capitalize flex items-center shrink-0 z-[1]">
              Email Title
            </div>
            <input
              type="text"
              placeholder="Email Title"
              value={notificationTitle}
              onChange={handleNotificationTitle}
              className="w-full [border:none] [outline:none] flex justify-between placeholder:text-black  self-stretch h-[2.60rem] px-3 rounded-3xs flex flex-row items-start justify-start py-[1rem] px-[1.125rem] box-border font-gilroy font-light text-[1.125rem] text-gray-400 min-w-[15.625rem] z-[1]"
              required
            />
          </div>
        </div>
        <div class="w-full flex-1 flex flex-col items-start justify-start gap-[1.5rem_0rem] min-w-[20.063rem] max-w-[30rem] xl:max-w-[40rem]">
          <div class="w-full self-stretch flex flex-col items-start justify-start gap-[0.875rem_0rem]">
            <div class=" w-full h-[0.75rem] relative leading-[1.125rem] capitalize flex items-center shrink-0 z-[1]">
              Select User's
            </div>
            <div class="w-full">
              <MultiSelect
                className="text-black h-[3.375rem] self-stretch"
                valueRenderer={(selected, _options) => {
                  return selected.length
                    ? selected.map(({ label }) => label + " , ")
                    : "No Items Selected";
                }}
                options={users}
                value={selectFromMS}
                onChange={setselectFromMS}
                labelledBy="Search and select users..."
              />
              {/* <UsersMultiSelect users={users} handleSelectedUsers={handleSelectedUsers}/> */}
            </div>
          </div>
        </div>
      </div>
      <div class="self-stretch flex flex-col mt-4 items-start justify-start gap-[0.875rem_0rem]">
        <div class="h-[0.75rem] relative leading-[1.125rem] capitalize flex items-center shrink-0 z-[1]">
          Email Text
        </div>
        <textarea
          class="w-full [border:none] [outline:none] z-0  self-stretch h-[12.375rem] rounded-3xs flex flex-row items-start justify-start py-[1.313rem] px-[1.125rem] box-border font-gilroy font-light text-[1.125rem] placeholder:text-black text-gray-400 min-w-[15.625rem] z-[1]"
          placeholder="Write Somthing.."
          value={notificationText}
          onChange={handleNotificationTextChange}
          type="text"
          required
        />
      </div>
      <button
        onClick={(event) => sendEmail(event)}
        disabled={isLoading}
        class={`cursor-pointer [border:none] mt-4 py-[1.063rem] pr-[2.563rem] pl-[2.625rem] rounded-3xs flex flex-row items-start justify-start z-[1] hover:bg-gainsboro-100 ${
          isLoading ? " bg-black " : "bg-white"
        } `}
      >
        <div class="h-[2.875rem] w-[8.813rem] relative rounded-3xs bg-white hidden"></div>
        <div
          class={`relative text-[1.125rem] leading-[0.75rem] capitalize font-gilroy 
          ${isLoading ? " text-white" : "text-gray-200 "} text-left z-[2]`}
        >
          {isLoading ? "Sending" : "Send"}
        </div>
      </button>
    </section>
  );
};

export default Email;
