import React from "react";
import { useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
const MultipleEmailForm = ({ Emails, onDelete, onAdd }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  function submitData(data) {
    onAdd(data);
  }
  function onerror(errors) {
    console.log(errors);
  }
  return (
    <>
      <form
        className="flex w-[100%] items-center gap-2 mt-2"
        onSubmit={handleSubmit(submitData, onerror)}
      >
        <label htmlFor="Enteremail">Enter Email:</label>
        <input
          id="Enteremail"
          type="email"
          className="px-2 py-[5px]"
          {...register("Enteremail", {
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Invalid email address",
            },
          })}
          placeholder="Enter email"
        />

        <button className="px-2 py-[8px] border-0 text-[17px] rounded-3">
          Add
        </button>
      </form>
      {errors?.Enteremail?.message ?? (
        <div style={{ color: "red", fontSize: "16px" }}>
          {errors?.Enteremail?.message}
        </div>
      )}
      <div className="w-[100%] max-h-60 overflow-x-auto flex flex-wrap mt-4 rounded-2 gap-2 ">
        {Emails.map((name, index) => {
          return (
            <div
              className="flex gap- px-2 py-1 rounded-2 bg-white items-center"
              id={index}
            >
              <div className="text-[black] text-[15px]">{name?.email}</div>
              <button
                onClick={() => onDelete(index)}
                className="border-0 bg-transparent"
              >
                <IoMdClose />
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MultipleEmailForm;
